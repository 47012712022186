<template>
  <ProblemFlightsList
    :fields="parsedFields" />
</template>
<script setup lang="ts">
  import ProblemFlightsList from './components/ProblemFlightsList.vue'

  const props = defineProps({
    fields: {
      type: Object,
      required: true
    }
  })

  const parsedFields = JSON.parse(props.fields.problemFlightsFields)
</script>
<style lang="less">
  @import "../../../../less/eu-claim.less";
</style>