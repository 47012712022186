// navigation fold out menu helper
//
// handles class switching for opening and closing the navigation and openeing and closing the navigation subitems
// huge differences in functionality for mobile and desktop
//
// Created by Matthias Poortvliet @ Lennoc Development 20/08/2019
// SETUP
const foldOutNav = () => {
  const MOBILE_BREAKPOINT = 992
  const FOLD_OUT_HEIGHT = 265
  const windowWidth = window.innerWidth

  const props = {
    delay: true,
    showMenu: true,
    timeout: 350,
    menuHeight: FOLD_OUT_HEIGHT,
    mobileBreakPoint: MOBILE_BREAKPOINT,
    animateElSelector: '.animate',
    itemSelector: '.foldoutnav__item',
    listItemContentSelector: '[data-foldoutnav-list-content]',
    noSCrollClass: 'no-scroll',
    openedClass: 'foldoutnav--open',
    triggeredClass: 'foldoutnav--triggered',
    triggeredBackgroundClass: 'foldoutnav--triggered-background', 
    list: document.querySelector('.foldoutnav__list'),
    listItems: document.querySelectorAll('[data-foldoutnav-list-toggle]'),
    cancelMenuItem: document.querySelectorAll('[data-foldoutnav-cancel]'),
    menu: document.querySelector('[data-foldoutnav]'),
    navToggleLists: document.querySelectorAll('[data-nav-toggle]')
  }

  // determine wether mobile
  props.isMobile = window.innerWidth < props.mobileBreakPoint

  // set toggles for mobile menu
  for (const navToggle of props.navToggleLists) {
    navToggle.addEventListener('click', (e) => {
      // open first item on mobile
      if (props.isMobile) {
        const parentElement = props.listItems[0].parentElement
        const animateEl = parentElement.querySelector(props.animateElSelector)
        openMobileMenuItem(props, parentElement, animateEl)
      }

      e.preventDefault()
      document.body.classList.toggle(props.noSCrollClass)
      props.menu?.classList.toggle(props.openedClass)
    })
  }

  // set event handler for desktop/mobile
  const eventHandler = event => props.isMobile
    ? mobileEventHandler(event, props)
    : desktopEventHandler(event, props)

  // set the events
  setEvents(props, eventHandler)

  // listen to resize to reset events
  window.addEventListener('resize', () => {
    if (window.innerWidth !== windowWidth) {
      setTimeout(() => {
        resetEvents(props, eventHandler)
      }, props.timeout)
    }
  })
}

// EVENT HANDLERS
// mobile event handler
const mobileEventHandler = (e, props) => {
  e.preventDefault()

  if (e.target.closest(props.itemSelector)) {
    const parentElement = e.target.closest(props.itemSelector)
    const animateEl = parentElement.querySelector(props.animateElSelector)
  
    !parentElement.classList.contains(props.triggeredClass)
      ? openMobileMenuItem(props, parentElement, animateEl)
      : closeMobileMenuItem(props, animateEl)
  } else {
    const link = e.target.closest(props.itemSelector).getAttribute('href')
    if (link) {
      window.location.href = link
    }
  }
}

// desktop event handler
const desktopEventHandler = (e, props) => {
  // add event blocker
  const node = createNode()
  e.target.parentElement.insertBefore(node, null)

  // skip setting the delay if skipDelay is set
  if (!props.skipDelay) {
    props.delay = true 
  }

  // first check for delay to see if the timeout has to be set
  if (props.delay) {
    setTimeout(() => {
      // second check for delay to see if the delay hasnt been cancelled during timeout
      if (props.delay) {
        openDesktopMenuItem(props, e)
      }
    }, 250)
  } else {
    openDesktopMenuItem(props, e)
  }

  // handle event blocker
  const eventBlocker = () => {
    setTimeout(() => {
      const eventBlocker = e.target.parentElement.querySelector('.block-event')
      if (eventBlocker) {
        eventBlocker.remove()
      }
    }, props.timeout)
  }

  // handle leaving the menu
  const menuLeave = () => {
    props.list.classList.remove(props.triggeredBackgroundClass)
    closeOtherDesktopMenuItems(props)
    props.delay = false
    props.skipDelay = false
  }

  // when mouseleave on listItem handle event blocker
  e.target.addEventListener('mouseleave', eventBlocker)

  // when mouseleave of whole menu or hover on childless item handle leaving the menu
  props.list.addEventListener('mouseleave', menuLeave)
  for (const cancel of props.cancelMenuItem) {
    cancel.addEventListener('mouseenter', menuLeave)
  }
}

// INTERACTIONS
// open mobile menu item
const openMobileMenuItem = (props, parentElement, animateEl) => {
  const listItemContent = parentElement.querySelector(props.listItemContentSelector)
  const height = listItemContent?.clientHeight

  for (const listItem of props.listItems) {
    const animateEl = listItem.parentElement.querySelector(props.animateElSelector)
    listItem.parentElement !== parentElement
      ? closeMobileMenuItem(props, animateEl)
      : undefined
  }
  animateEl.style.height = height + 'px',
  parentElement.classList.add(props.triggeredClass)
}

// close mobile menu item
const closeMobileMenuItem =(props, el) => {
  el.style.height = 0 + 'px',
  el.parentElement.classList.remove(props.triggeredClass)
}

// open desktop menu item
const openDesktopMenuItem = (props, e) => {
  closeOtherDesktopMenuItems(props)
  const animateEl = e.target.parentElement.querySelector(props.animateElSelector)
  animateEl.style.height = props.menuHeight + 'px'
  props.skipDelay = true
  e.target.parentElement.classList.add(props.triggeredClass)
  props.list.classList.add(props.triggeredBackgroundClass)
}

// close desktop menu item
const closeOtherDesktopMenuItems = (props) => {
  const openListItem = props.list.querySelector(`.${props.triggeredClass}`)
  if (openListItem) {
    openListItem.classList.remove(props.triggeredClass)
  }
}

// reset all events
const resetEvents = (props, eventHandler) => {
  document.body.classList.remove(props.noSCrollClass)

  for (const listItem of props.listItems) {
    listItem.removeEventListener('click', eventHandler)
    listItem.removeEventListener('mouseover', eventHandler)

    if (!props.isMobile) {
      const animateElements = props.list.querySelectorAll(props.animateElSelector)
      for (const animateEl of animateElements) {
        animateEl.style.height = 0 + 'px'
      }
    }
    setEvents(props, eventHandler)
  }
}

// set events
const setEvents = (props, eventHandler) => {
  // click/hover event based on mobile/not mobile for every list item
  for (const listItem of props.listItems) {
    props.isMobile
      ? listItem.addEventListener('click', eventHandler)
      : listItem.addEventListener('mouseover', eventHandler)
  }
}

// create event blocking node
const createNode = () => {
  const node = document.createElement('div')
  node.setAttribute('class', 'block-event')
  return node
}

export default foldOutNav()
