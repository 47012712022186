<template>
  <AirlinesAirportsList
    :settings="settings" />
</template>
<script setup lang="ts">
  import AirlinesAirportsList from './components/AirlinesAirportsList.vue'

  const props = defineProps({
    fields: {
      type: Object,
      required: true
    }
  })

  const key = `setting${props.fields.airlinesAirportsMount
    .toLowerCase()
    .charAt(0)
    .toUpperCase() + props.fields.airlinesAirportsMount.substring(1)}`

  const listSpecificSetting = JSON.parse(props.fields.airlinesAirportsFields)[key]

  const settings = {
    listType: listSpecificSetting.split('_')[0]  === 'delayed' ? 'delays' : listSpecificSetting.split('_')[0],
    contentType: listSpecificSetting.split('_')[1]
  }
</script>
<style lang="less">
  @import "../../../../less/eu-claim.less";
</style>