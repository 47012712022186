<template>
  <div
    class="block__wrapper"
    tabindex="0"
    @keydown="keyHandler($event, $event.key)"
  >
    <div class="block__header">
      <h2 class="block__title title--l">{{ fields.title }}</h2>
    </div>
    <div class="block__content">
      <div
        v-if="dots"
        class="slider__arrow slider__arrow--left"
        @click="setTrackPos(trackPos - 1)"
      >
        <Icon :image="'chevron-left'" />
      </div>
      <div
        ref="sliderCore"
        class="slider__core"
      >
        <div
          class="slider__track"
          data-slider-track
        >
          <component
            :is="mapComponent(fields.sliderType)"
            v-for="(slide, index) in fields.slides"
            :key="index"
            :fields="slide"
            :trustpilotAuthorText="fields.trustpilotAuthorText"
            @touchstart.passive="touchHandler($event)"
            :showGreyImage="fields.showGreyImage"
          />
        </div>
      </div>
      <div
        v-if="dots"
        class="slider__arrow slider__arrow--right"
        @click="setTrackPos(trackPos + 1)"
      >
        <Icon :image="'chevron-right'" />
      </div>
      <div class="slider__dots">
        <div
          v-for="(slide, index) in dots"
          :key="index"
          class="slider__dots--dot"
          :class="getActiveClass(index)"
          @click="setTrackPos(index)"
        />
      </div>
      <a
        v-if="fields.buttonText"
        class="button button--prim"
        :href="fields.buttonLink"
      >{{ fields.buttonText }}</a>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import ContentSlide from './ContentSingleSlide.vue'
  import ImageSlide from './ImageSingleSlide.vue'
  import Icon from '../../../assets/shared-components/Icon.vue'

  const props = defineProps({
    sliderData: {
      type: Object,
      required: true
    }
  })

  let dots = ref()
  let trackPos = ref()
  const fields = ref(props.sliderData)
  const sliderCore = ref()

  onMounted(() => {
    calculateDots()
    setTrackPos(trackPos.value)
    setWidth()
    setResize()
  })

  const mapComponent = (sliderType: string) => {
    return sliderType === 'image_slider'
      ? ImageSlide
      : ContentSlide
  }

  const setTrackPos = (slideIndex: string | number | symbol) => {
    const sliderTrack: HTMLElement = sliderCore.value.querySelector('[data-slider-track]')
    !slideIndex
      ? trackPos.value = 0
      : trackPos.value = slideIndex
    const slidesLength = slidesToView()
      ? (fields.value.slides.length + 1) - slidesToView()
      : fields.value.slides.length
    trackPos.value >= slidesLength
      ? trackPos.value = 0
      : trackPos.value <= -1
      ? trackPos.value = slidesLength - 1
      : false

    const slideWidth: number = sliderTrack.querySelector('[data-slider-element]')
      ? sliderCore.value.querySelector('[data-slider-element]').clientWidth
      : 0
    const offSet: number = trackPos.value > 0 
      ? trackPos.value * slideWidth
      : 0
    sliderTrack.style.marginLeft = - offSet + 'px'
  }

  const setWidth = () => {
    const sliderTrack: HTMLElement = sliderCore.value.querySelector('[data-slider-track]')
    const slides: NodeListOf<HTMLElement> = sliderTrack.querySelectorAll('[data-slider-element]')
    for (const slide of slides) {
      const visibleSlidesAMount : number = parseInt(slidesToView())
      const slideWidth = visibleSlidesAMount
        ? (sliderCore.value.clientWidth / visibleSlidesAMount) + 'px'
        : (sliderCore.value.clientWidth) + 'px'
      slide.style.width = slideWidth
    }
    const trackWidth = ( sliderCore.value.clientWidth * fields.value.slides.length)
    sliderTrack.style.width = trackWidth + 'px'
  }

  const getActiveClass = (index: string | number | symbol) => {
    return trackPos.value === index
      ? 'slider__dots--dot-active'
      : false
  }

  const calculateDots = () => {
    dots.value = fields.value.slidesVisible
      ? (fields.value.slides.length + 1) - slidesToView()
      : fields.value.slides
    parseInt(fields.value.slidesVisible) === fields.value.slides.length || fields.value.slides.length === 1
      ? dots.value = 0
      : false
  }

  const setResize = () => {
    window.addEventListener('resize', () => {
      setTrackPos(trackPos.value)
      setWidth()
      calculateDots()
    })
  }
 
  const slidesToView = () => {
    const viewPort = window.innerWidth
    return viewPort < 480 && fields.value.slidesVisible > 2
      ? 2
      : viewPort < 768 && fields.value.slidesVisible > 2
      ? 3
      : fields.value.slidesVisible || false
  }

  const touchHandler = (moveStart: TouchEvent) => {
    const detectStop = (moveStop: TouchEvent) => {
      const oldX = moveStart.changedTouches[0].screenX
      const newX = moveStop.changedTouches[0].screenX
      newX < oldX
        ? setTrackPos(trackPos.value + 1)
        : setTrackPos(trackPos.value - 1)
      window.removeEventListener('touchend', detectStop, true)
    }
    window.addEventListener('touchend', detectStop)
  }

  const keyHandler = (e: Event, type: string) => {
    type === 'ArrowRight'
      ? setTrackPos(trackPos.value + 1)
      : type === 'ArrowLeft'
      ? setTrackPos(trackPos.value - 1)
      : false
  }
</script>
