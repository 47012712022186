// ab test js setup v2.0
//
// updated to vanilla
//
// little module thats set to the window and can be called via tagmanager to add a denumerator
// for an ab test variant
// this ab test variant denumerator is than set as a class and as a data attribute to the body of the website so 
// chamges can be made acccordingly

// written by Matthias Poortvliet
const abTest = (data) => {
  if (data) {
    document.body.classList.add(`abtest--${data}`)
    document.body.setAttribute('data-ab-test', data)
  }
}

window.abTest = abTest

export default abTest()