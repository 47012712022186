// faq helper v2.0
//
// converted to vanilla
//
// trims the id's so they can be used as hash links
//
// Created by Matthias Poortvliet @ Lennoc Development 19/01/2021
const faqHelper = () => {
  const faqSelector = '[data-faq-id]'
  const contentSelector = '[data-collapsible="content"]'
  const triggeredClass = 'collapsible--triggered'

  const faqElements = document.querySelectorAll(faqSelector)

  for (const faqElement of faqElements) {
    // TODO: make a good regex that does this in one function
    const id = faqElement.id.replace('?', '').replace(',', '').replace(/ /g, '-').toLowerCase()
    faqElement.setAttribute('id', id)
  }
  
  const id = decodeURIComponent(window.location.hash).replace('#', '')
  const target = document.getElementById(id)

  if (target) {
    target.scrollIntoView()
    if (target) {
      const content = target.querySelector(contentSelector)
      if (content) {
        const height = content.scrollHeight
        content.style.height = height + 'px'
        target.classList.add(triggeredClass)
      }
    }
  }
}

export default faqHelper()