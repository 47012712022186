<template>
  <div class="slider__item" data-slider-element>

    <div class="slider__content">
      <a v-if="slideFields.article_link && showGreyImage" class="container-link container-link-position" :href="slideFields.article_link"
        :target="slideFields.target">
        <div :class="getClass()">
          <img loading="lazy" alt="" :src="slideFields.image_source" :class="{ 'slider-image': showGreyImage }">

        </div>
      </a>
      <div v-else :class="getClass()">
        <img loading="lazy" alt="" :src="slideFields.image_source" :class="{ 'slider-image': showGreyImage }">
        <a v-if="slideFields.article_link" class="container-link" :href="slideFields.article_link"
          :target="slideFields.target" />
      </div>

    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  fields: {
    type: Object,
    required: true,
    stars: {
      type: String
    },
    post_content: {
      type: String
    },
    name: {
      type: String
    }
  },
  showGreyImage: {
    type: Boolean,
    default: false
  }
})

const slideFields = ref(props.fields)

const getClass = () => {
  return `aspect-ratio ${slideFields.value.image_orientation ? slideFields.value.image_orientation : ''} aspect-ratio--3-2`
}
</script>
