// open navigation mobile v2.0
//
// updated to vanilla js
//
// handles class switching for opening and closing the navigation on mobile
//
// Created by Matthias Poortvliet @ Lennoc Development 20/08/2019
const openNavigation = () => {
  const hamburger = document.querySelector('[data-hamburger]');

  hamburger?.addEventListener('click', (e) => {
    e.preventDefault();
    document.body.classList.toggle('navigation--open');
  })
}

export default openNavigation()