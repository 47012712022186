import { cultureCheck } from './cultureCheck'
import day from 'dayjs'

const dateFormatOnCulture = (parseFormat, date) => {
  if (parseFormat === 'writtenDate') {
    return cultureCheck() === 'de-DE'
      ? day(date).format('dddd') + ', ' + day(date).format('D. MMMM YYYY')
      : day(date).format('dddd') + ' ' + day(date).format('D MMMM YYYY')
  }

  if (parseFormat === 'dateAndTime') {
    return cultureCheck() === 'de-DE'
      ? day(date).format('DD.MM.YYYY') + ' um ' + day(date).format('HH:mm') + ' Uhr'
      : cultureCheck() === 'nl-NL'
        ? day(date).format('DD-MM-YYYY') + ' om ' + day(date).format('HH:mm') + ' uur'
        : day(date).format('DD-MM-YYYY') + ' at ' + day(date).format('HH:mm') + ' hour'
  }

  if (parseFormat === 'time') {
    return 'HH:mm:ss'
  }

  return cultureCheck() === 'nl-NL'
    ? 'DD-MM-YYYY'
    : cultureCheck() === 'de-DE'
      ? 'DD.MM.YYYY'
      : 'DD/MM/YYYY'
}

export { dateFormatOnCulture }
