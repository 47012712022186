// lazy loader v1
//
// Handles the lazy loading of iframes
//
// Created by Matthias Poortvliet @ Lennoc Development 28/04/2021
function lazyLoader () {
  const lazyObjects = document.querySelectorAll('[data-lazy-target]')
  const objectsArray = Array.prototype.slice.call(lazyObjects)

  try {
    const observer = new IntersectionObserver(function(entries, observer) {
      entries.forEach((lazyObject) => {
        if (lazyObject.isIntersecting) {
          const object = lazyObject.target
          object.src = object.dataset.lazySrc
          observer.unobserve(object)
        }
      })
    })
  
    objectsArray.forEach(function (lazyObject) {
      observer.observe(lazyObject);
    })
  } catch (e) {
    console.log('Warning: No lazyloading because IntersectionObserver isn\'t available on this device.')
  }
}

export default lazyLoader()