// pop up manager     v2
//
// updated to vanilla
//
// Handles the opening of a Modal on a click on an anchor.
// For everywhere use of simple content Modals.
// Gets target from attribute in trigger element, so can be used dynamically
// Opens single background instance if a modal is present
//
// Created by Matthias Poortvliet @ Lennoc Development 12/08/2019
const modalManager = () => {
  const toggleModalSelector = '[data-toggle-modal]'
  const modalAttributeSelector = '[data-toggle-modal]'
  const toggleModalTriggers = document.querySelectorAll(toggleModalSelector)

  handleBackground()

  for (const toggleModalTrigger of toggleModalTriggers) {
    const modalSelectorId = toggleModalTrigger.getAttribute(modalAttributeSelector.replace(/[\[\]']+/g, ''))
    const modal = document.querySelector(modalSelectorId)

    toggleModalTrigger.addEventListener('click', (e) => {
      e.stopPropagation()
      toggleModal(modal)
    })
  }
  window.toggleModal = toggleModal
}

const toggleModal = (modal) => {
  const modalVisibleClass = 'modal--visible'
  modal.classList.toggle(modalVisibleClass)
  handleBackground()
}

const handleBackground = () => {
  const modalVisibleSelector = '.modal.modal--visible'
  const modalVisibleClass = 'modal--visible'
  const backgroundModalSelector = '[data-modal-background]'
  const modalBackground = document.querySelector(backgroundModalSelector)

  setTimeout(function() { 
    const openModals = document.querySelectorAll(modalVisibleSelector)
    const isCookieModalPresent = checkForCookieModal(openModals)

    if (!isCookieModalPresent) {
      for (const openModal of openModals) {
        modalBackground.addEventListener('click', (e) => {
          e.stopPropagation()
          closeModal(openModal, modalVisibleClass)
        })
      }
    }

    openModals.length
      ? modalBackground.classList.add(modalVisibleClass)
      : modalBackground.classList.remove(modalVisibleClass)
  }, 100)
}

const closeModal = (modal, modalVisibleClass) => {
  modal.classList.remove(modalVisibleClass)
  handleBackground()
}

const checkForCookieModal = (openModals) => {
  let check
  for (const openModal of openModals) {
    check = openModal.classList.contains('cookie')
  }
  return check
}

export default modalManager()