// Cookiemanager 1.0
//
// Cookie manager according to MDN script https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
// updated to vanilla js
//
// Created for Lennoc Development 03/12/2019
const cookieManager = () => {
  // Disable the cookie manager in the .nl site, because we have an external cookie wall
  const isNL = window.location.origin.endsWith('.nl');
  if(isNL) {
    return
  }
  const docCookies = setCookieLib
  const cookies = docCookies.keys()

  for (const cookie of cookies) {
    if (cookie === 'lennoc_cookies_accepted') {
      return
    }
    if (cookie !== 'PHPSESSID') {
      docCookies.removeItem(cookie)
    }
  }
  handleCookieBanner(docCookies)
}

const handleCookieBanner = (docCookies) => {
  const target = document.querySelector('.cookie')
  const openSettingsButton = document.querySelector('[data-cookie="settings"]')
  const trackingInput = document.querySelector('[data-cookie-input="tracking"]')
  const cookieSettingsList = document.querySelector('.cookie__checkmarks')
  const cookieList = document.querySelector('.cookie__list')
  const cookieAcceptButton = document.querySelector('[data-cookie="accept"]')
  const cookieAcceptSaveButton = document.querySelector('[data-cookie="accept-save"]')

  if(typeof openSettingsButton !== 'undefined' && openSettingsButton  !== null ){
    openSettingsButton.addEventListener('click', (e) => {
      openCookieSettings(e, cookieSettingsList, cookieList, openSettingsButton, cookieAcceptButton, cookieAcceptSaveButton)
    })
  }

  if(typeof cookieAcceptButton !== 'undefined' && cookieAcceptButton  !== null ) {
  cookieAcceptButton.addEventListener('click', (e) => {
    e.stopPropagation()
    acceptCookies(target, docCookies, trackingInput)
  })
  }

  if(typeof cookieAcceptSaveButton !== 'undefined'  && cookieAcceptSaveButton  !== null) {
  cookieAcceptSaveButton.addEventListener('click', (e) => {
    e.stopPropagation()
    acceptCookies(target, docCookies, trackingInput)
  })
 }
}

const openCookieSettings = (e, cookieSettingsList, cookieList, openSettingsButton, cookieAcceptButton, cookieAcceptSaveButton) => {
  e.preventDefault()
  cookieSettingsList.classList.add('cookie__checkmarks--visible')
  cookieList.classList.remove('cookie__list--visible')
  e.srcElement.classList.remove('open-settings--visible')
  cookieAcceptButton.classList.add('button--invisible')
  openSettingsButton.classList.add('button--invisible')
  openSettingsButton.classList.remove('open-settings--visible')
  cookieAcceptSaveButton.classList.remove('button--invisible')
}

const openMoreInfo = (cookieInfo) => {
  cookieInfo.classList.toggle('cookie__information--visible')
}

const acceptCookies = (target, docCookies, trackingInput) => {
  if(trackingInput.checked) {
    docCookies.setItem('lennoc_cookies_accepted', 'all', Infinity)
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({'event': 'AcceptCookiepopup','eventAction': '2'});
    }
  }
  else {
    docCookies.setItem('lennoc_cookies_accepted', 'noTracking', Infinity)
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({'event': 'AcceptCookiepopup','eventAction': '1'});
    }
  }
  toggleModal(target)
}

export default cookieManager()