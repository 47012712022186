// copy to clipboard helper v2.0
//
// converted to vanilla
//
// handles the copying of targeted content to the clipboard and replacing to text in the link
//
// Created by Matthias Poortvliet @ Lennoc Development 11/12/2019
const copyToClipboard = () => {
  const triggerSelector = '[data-copy-to-clipboard="trigger"]'
  const itemSelector = '.post-list__item'
  const targetSelector = '[data-copy-to-clipboard="target"]'
  const triggeredClass = 'post-list__item--copied'

  const triggerElements = document.querySelectorAll(triggerSelector)

  for (const trigger of triggerElements) {
    trigger.addEventListener('click', (e) => {
      handleCopy(e)
    })
  }

  const handleCopy = (e) => {
    e.preventDefault()
    const parent = e.srcElement.closest(itemSelector)
    const target = parent.querySelector(targetSelector)
    const selected = target.innerHTML
    const input = document.createElement('textarea')

    input.value = selected
    parent.appendChild(input)
    input.select()
    document.execCommand('copy')

    parent.removeChild(input)
    parent.classList.add(triggeredClass)
  }
}

export default copyToClipboard()