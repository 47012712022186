<template>
  <Spinner
    :isfullscreen="''"
    v-if="busy"
  />
  <ul class="airlines-foldout__list"
     v-for="(splitList, index) in list"
     :key="index">
    <div class="airlines-foldout__item"
      v-for="(item, index) in splitList"
      :key="index">
      <a class="container-link" :href="item.link"></a>
      <span>{{ item.title.rendered }}</span>
    </div>
  </ul>
</template>
<script setup lang="ts">
  import Spinner from '../../../assets/shared-components/Spinner.vue'
  import { onMounted, ref } from 'vue'
  import { AirlineAirportItem } from '../../../types'
  import { splitArrayOnColumn } from '../../../assets/utils/splitArrayOnColumn'
  import { sortArray } from '../../../assets/utils/sortArray'
  import { cultureCheck } from '../../../assets/utils/cultureCheck'
  import excludeList from '../local-libs/excludeItems.json'

  let busy = ref(true)

  const props = defineProps({
    settings: {
      type: Object,
      required: true
    }
  })

  const url = new URL(location.href).origin.includes('localhost')
    ? 'http://dev.euclaim.nl'
    : new URL(location.href).origin
  const endpoint = `api-v2/wp/cancelleddelayed`
  const form = new FormData
  form.append('flightType', props.settings.listType)
  const config = {
    method: 'POST',
    body: form
  }

  const list = ref<any>([])

  const mapCategories = (category : number) => {
    if(cultureCheck().includes('NL')) {
      switch(category) {
        case 17:
          return 'airlines'
        case 19:
          return 'airports'
        case 137:
          return 'countries'
        default:
          return 'other'
      }
    }
    else if (cultureCheck().includes('DE')) {
      switch(category) {
        case 2:
          return 'airlines'
        case 3:
          return 'airports'
        case 26:
          return 'countries'
        default:
          return 'other'
      }
    }
    else if (cultureCheck().includes('en')) {
      switch(category) {
        case 20:
          return 'airlines'
        case 47:
          return 'airports'
        case 73:
          return 'countries'
        default:
          return 'other'
      }
    }
  }
  
  onMounted (async () => {
    const response = await fetch(`${url}/${endpoint}`, config).then((list) => list.json())
    const setAirlines = (listItem : AirlineAirportItem) => {
      if (mapCategories(listItem.categories[0]) === 'airlines') {
        list.value.push(listItem)
      }
    }
    const setAirports = (listItem : AirlineAirportItem) => {
      if (mapCategories(listItem.categories[0]) === 'airports') {
        listItem.title.rendered = listItem.title.rendered.replace(' Airport', '')
        list.value.push(listItem)
      }
    }
    const setCountries = (listItem : AirlineAirportItem) => {
      if (mapCategories(listItem.categories[0]) === 'countries') {
        list.value.push(listItem)
      }
    }
    for (const l of response) {
      !excludeList.find(e => e === l.title.rendered)
        ? props.settings.contentType === 'airlines'
          ? setAirlines(l)
          : props.settings.contentType === 'airports'
            ? setAirports(l)
            : setCountries(l)
        : false
    }
    list.value = sortArray(list.value, 'title', 'rendered')
    list.value = splitArrayOnColumn(list.value, 4)
    busy.value = false
  })
</script>