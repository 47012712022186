// form helper v2
//
// updated to vanilla js
//
// handles ui and posting for custom forms
// works with strict markup -> check twig/blocks/form-partner for functional example
// uses api endpoint from markup (api endpoint var set on form in html)
//
// uses handleCustomFormInteraction function to handle custom stuff for particlular forms
//
// Created by Matthias Poortvliet @ Lennoc Development 20/08/2019
const formManager = () => {
  const inputElements = document.querySelectorAll('.form input')
  const formSubmit = document.querySelector('[data-submit-form]')
  const spinner = document.querySelector('[data-form-spinner]')
  const apiRoute = formSubmit?.getAttribute('data-submit-form')

  if (formSubmit) {
    formSubmit.addEventListener('click', (e) => {
      e.preventDefault()
      handleSubmit(inputElements, spinner, apiRoute, e)
    })
    handleCustomFormInteraction()
  }
}

const handleSubmit = (inputElements, spinner, apiRoute, e) => {
  if (simpleValidation(inputElements)) {
    const form = new FormData();
    for (const inputElement of inputElements) {
      form.append(inputElement.id, inputElement.value === 'on' ? inputElement.checked : inputElement.value)
    }
    handleCall(spinner, form, apiRoute, e.srcElement)
  }
}

const handleCall = (spinner, form, apiRoute, eventTarget) => {
  const request = new XMLHttpRequest()
  const url = new URL(window.location.href)
  const apiEndpoint = url.origin + '/api-v2/' + apiRoute

  spinner.style.display = 'block'
  request.open("POST", apiEndpoint, true)
  request.onload = (e) => {
    const response = e.currentTarget.response
    if (!response.includes('Slim Application Error')) {
      const result = JSON.parse(response).result
      if (result === 'succes') {
        setResponseMessages('succes', eventTarget)
      }
    } else {
      setResponseMessages('failed', eventTarget)
    }
    spinner.style.display = 'none'
  }
  request.send(form)
}

const simpleValidation = (inputElements) => {
  let errors = 0
  for (const inputElement of inputElements) {
    clearValidation(inputElement)
    if (inputElement.getAttribute('required') !==null && inputElement.value == '') {
      setInputError('set', inputElement)
      inputElement.closest('.form__content').style.height = 'auto'
      errors++
    }
  }
  return !errors
    ? true
    : false
}

const clearValidation = (inputElement) => {
  inputElement.addEventListener('click', (e) => {
    setInputError('clear', e.srcElement)
  })

  inputElement.addEventListener('keydown', (e) => {
    setInputError('clear', e.srcElement)
  })
}

const setInputError = (set, el) => {
  if (set === 'api') {
    el.closest('.form__content').querySelector('.form__group--error').style.display = 'block'
    el.closest('.form__content').querySelector('.form__group--error').querySelector('.error').style.display = 'inline-block'
    return
  }
  if (set === 'set') {
    el.closest('.form__group').classList.add('form__group--error')
    el.closest('.form__group').querySelector('.error').style.display = 'inline-block'
  } else {
    el.closest('.form__group').classList.remove('form__group--error')
    const error = el.closest('.form__group').querySelector('.error')
    if (error) {
      error.style.display = 'none'
    }
  }
}

const setResponseMessages = (data, eventTarget) => {
  if (data === 'succes') {
    eventTarget.closest('.form__content').querySelector('.form__group--message').style.display = 'block'
  }
  if (data === 'failed') {
    eventTarget.closest('.form__content').querySelector('.form__group--error').style.display = 'block'
    setInputError('api', eventTarget)
  }
  eventTarget.closest('.form__content').style.height = 'auto'
}

const handleCustomFormInteraction = () => {
  const choice = document.querySelector('[data-simple-drop-down-reset="form"]')
  const customOption = document.querySelector('[data-simple-drop-down-reset]')
  if (choice) {
    choice.style.display = 'none'
    customOption.addEventListener('click', (e) => {
      e.preventDefault()
      const dropdown = e.target.closest('.dropdown')
      dropdown.style.display = 'none'
      choice.style.display = 'block'
    })
  }
}

export default formManager()