const cultureCheck = () => {
  const url = new URL(window.location.href)
  if (url.hostname.includes('.nl')) {
    return 'nl-NL'
  }
  if (url.hostname.includes('.de')) {
    return 'de-DE'
  }
  if (url.hostname.includes('.com')) {
    return 'en'
  }
  return 'nl-NL'
}

export { cultureCheck }
