// chat helper v2
//
// updated to vanilla
//
// TODO: make a promise based on wether the script is loaded instead of an interval which does the check
//
// reversed engineering from old Chatbutton.js
//
// handles the setting of buttons
//
// Created by Matthias Poortvliet @ Lennoc Development 15/09/2020
const chatHelper = () => {
  const generalFields = JSON.parse(document.currentScript.getAttribute('generalFields'))
  const chatFields = JSON.parse(document.currentScript.getAttribute('chatFields'))

  const liveChatEndpoint = generalFields.livechat_settings.url
  const username = generalFields.livechat_settings.username
  const key = generalFields.livechat_settings.key

  const chatButton = document.querySelector('[data-chat-button]')
  const inputElement = document.querySelector('[data-chat="popup"]')

  // expose chat function
  window.loadEvilChat = loadEvilChat

  // Set the license and the chat group
  window.__lc = window.__lc || {};
  window.__lc.license = 3057352;
  window.__lc.params = [{
    name: 'LiveChatConversion',
    value: false
  }];
  window.__lc.group = chatFields.group;

  inputElement?.addEventListener('click', (e) => {
    e.preventDefault()

    loadEvilChat()

    if (chatButton.getAttribute('disabled')) {
      const request = new XMLHttpRequest()
      const url = new URL(window.location.href)
      const apiEndpoint = url.origin + '/api-v2/external-request'
    
      request.open("POST", apiEndpoint, true)
      request.onload = (e) => {
        const response = JSON.parse(e.currentTarget.response)
        setButton(chatFields, chatButton, response)
      }
  
      const data = new FormData()
      data.append('url', liveChatEndpoint)
      data.append('username', username)
      data.append('password', key)
      request.send(data)
    }
  })
}

const setButton = (chatFields, chatButton, chatResponse) => {
  const spinner = document.querySelector('[data-chat-button-spinner]')
  const text =  document.querySelector('[data-chat-text]')

  const chatOnline = () => {
    chatButton.removeAttribute('disabled')
    spinner.style.display = 'none'
  }

  const chatOffline = () => {
    chatButton.removeAttribute('disabled')
    spinner.style.display = 'none'
    chatButton.innerText = chatFields.button_closed
    if (text) {
      text.style.display = 'block'
    }
  }

  chatResponse.status === 'online' 
    ? chatOnline()
    : chatOffline()
}

const loadEvilChat = (data) => {
  const setScript = () => {
    const lc = document.createElement('script')
    lc.type = 'text/javascript'
    lc.async = true
    lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(lc, s)
  }

  setScript()
  
  if (data && data.open) {
    const stopInterval = () => {
      clearInterval(interval)
    }
    const checkForChat = () => {
      if (window.LC_API && typeof window.LC_API.open_chat_window === 'function') {
        stopInterval()
        window.LC_API.open_chat_window()
      }
    }
    const interval = setInterval(checkForChat, 50)
  }
} 

export default chatHelper()