// problemflight helper v2.0
//
// updated to vanilla js
//
// sets classes and calculates height for showing and hiding problem flights element 
//
const problemFlightHelper = () => {
  const itemsToShowSelector = '[data-flight-to-show]'
  const listSelector = '[data-problem-flight-list]'
  const itemSelector = '.problemflights__item'
  const triggerSelector = '[data-show-flights="trigger"]'
  const openListClass = 'problemflights__list--open'

  const items = document.querySelectorAll(itemSelector)
  const list = document.querySelector(listSelector)

  if (list) {
    const itemsToShow = document.querySelector(itemsToShowSelector).getAttribute(itemsToShowSelector.replace(/[\[\]']+/g, ''))
    calculateListHeight(items, list, itemsToShow, openListClass)

    const triggerElements = document.querySelectorAll(triggerSelector)
    for(const triggerElement of triggerElements) {
      triggerElement.addEventListener('click', () => {
        calculateListHeight(items, list, itemsToShow, openListClass)
      })
      triggerElement.addEventListener('touch', () => {
        calculateListHeight(items, list, itemsToShow, openListClass)
      })
    }
  }
}

const calculateListHeight = (items, list, itemsToShow, openListClass) => {
  let height
  list.classList.contains(openListClass)
    ? height = items[0].scrollHeight * (items.length / 2) + 'px'
    : height = items[0].scrollHeight * (itemsToShow / 2) + 'px'
  list.style.maxHeight = height
  list.classList.toggle(openListClass)
}

export default problemFlightHelper()