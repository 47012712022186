
let originalReferrer = sessionStorage.getItem('originalReferrer')

if(!originalReferrer)
{
    originalReferrer = window.location.href
    sessionStorage.setItem('originalReferrer', originalReferrer)
}

document.body.setAttribute('original-referrer', originalReferrer)

export default {}