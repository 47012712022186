<template>
  <SliderView
    :slider-data="slider"
  />
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { SliderData, SingleSlideReview, SingleSlideImage } from '../../types'
  import SliderView from './components/SliderView.vue'
  
  const props = defineProps({
    fields: {
      type: Object,
      required: true
    }
  })

  const sliderFields = ref(props.fields)
  const sliderData = JSON.parse(sliderFields.value.sliderFields)
  const list : Array<SingleSlideReview | SingleSlideImage> = sliderData.item

  const slider : SliderData = {
    title: sliderData.title,
    sliderType: sliderData.slider_type,
    buttonText: sliderData.button.text,
    buttonLink: sliderData.button.link,
    slidesVisible: sliderData.slides_visible,
    slides: list,
    trustpilotAuthorText: sliderData.trustpilot_author_text,
    showGreyImage: sliderData.show_grey_image
  }
</script>
<style lang="less">
  @import "../../../../less/eu-claim.less";
</style>