<template>
    <div :class="getInstanceClass()">
      <div class="spinner">
        <div class="spinner__ring">
        </div>
        <div class="spinner__ring">
        </div>
        <div class="spinner__ring">
        </div>
        <div class="spinner__ring">
        </div>
        <div class="spinner__ring--bg">
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue'

  const props = defineProps({
    isfullscreen: {
      type: String,
      default () {
        return true
      }
    },
    domclass: {
      type: String,
      default () {
        return ''
      }
    }
  })

  let fadeIn = false

  const getInstanceClass = () => {
    return [
      props.domclass,
      !props.isfullscreen ? 'spinner__local' : 'spinner__full-screen',
      fadeIn ? 'spinner--fade-in' : ''
    ].join(' ')
  }
  
  onMounted(() => {
    setTimeout(() => {
      fadeIn = true
    }, 1)
  })
</script>
