<template>
  <div
    class="slider__item"
    data-slider-element
  >
    <div class="slider__content">
      <div
        v-if="slideFields.stars"
        class="slider__rating"
      >
        <Icon :image="`rating--${slideFields.stars}`" />
      </div>
      <div class="group">
        <div class="text">
          {{ slideFields.post_content }}
        </div>
      </div>
      <div
        v-if="slideFields.name"
        class="slider__author">
        <p class="text">
          {{ trustpilotAuthorText }} {{ slideFields.name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { ref } from 'vue'
  import Icon from '../../../assets/shared-components/Icon.vue'

  const props = defineProps({
    fields: {
      type: Object,
      required: true,
      stars: {
        type: String
      },
      post_content: {
        type: String
      },
      name: {
        type: String
      }
    },
    trustpilotAuthorText: {
      type: String,
      default: ''
    }
  })
  
  const slideFields = ref(props.fields)
</script>
