import { createApp } from 'vue'
import App from './App.vue'

const keyAttr = 'data-slider-mount'
const fieldAttr = 'data-slider-fields'

const mounts = document.querySelectorAll(`[${keyAttr}]`) as NodeListOf<HTMLElement>
for (const mount of mounts) {
  const props = {
    fields: {
      ...mount.dataset
    }
  }
  const indentifier = mount.getAttribute(keyAttr)
  createApp(App, {
    ...props
  }).mount(`[${keyAttr}="${indentifier}"]`)
  mount.removeAttribute(keyAttr)
  mount.removeAttribute(fieldAttr)
}