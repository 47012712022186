const sortArray = (array, keyPartOne, keyPartTwoOptional) => {
  return array.sort((a, b) => {
    const titleA = keyPartTwoOptional 
      ? a[keyPartOne][keyPartTwoOptional].toUpperCase()
      : a[keyPartOne].toUpperCase()
    const titleB = keyPartTwoOptional 
    ? b[keyPartOne][keyPartTwoOptional].toUpperCase()
    : b[keyPartOne].toUpperCase()
    
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    return 0
  })
}

export { sortArray }