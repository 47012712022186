<template>
  <span
    class="icon"
    :class="domclass"
  >
    <svg><use :xlink:href="imgurl()" /></svg>
  </span>
</template>

<script setup lang="ts">
  const props = defineProps({
    image: {
      type: String,
      required: true
    },
    domclass: {
      type: String,
      default: ''
    }
  })

  const imgurl = () => {
    return `/wp-content/themes/euclaim/img/sprite.svg#${props.image}`
  }
</script>