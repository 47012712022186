const splitArrayOnColumn = (array, columns) => {
  let arr_offset = Math.ceil(array.length/columns);
  let arr_index = 0;
  let splittedArray = []
  for (let i = 0; i < columns; i++){
    splittedArray[i] = array.slice(arr_index, (arr_offset + arr_index));
    arr_index += arr_offset;
  }

  return splittedArray
}

export {
  splitArrayOnColumn
}