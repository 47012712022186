
<template>
  <Spinner
    :isfullscreen="''"
    v-if="busy"
  />
  <ul class="problemflights__list" v-if="list.length > 0"
    ref="domList">
    <li class="problemflights__item"
      v-for="(item, index) in list" 
      :index="item"
      ref="domItem">
      <div class="problemflights__content">
          <div class="flight__code">{{ item.FlightCode }}</div>
          <div class="flight">
              <div class="flight__date">
                <Icon image="calendar" />
                <span class="flight__time">{{ dateFormatOnCulture('dateAndTime', item.DepartureScheduledDateTime) }}</span>
              </div>
              <div class="flight__departure">
                <Icon image="airplane-take-off" />
                <span>{{ item.DepartureAirport }}</span>
              </div>
              <div class="flight__arrival">
                <Icon image="airplane-landing" />
                <span>{{ item.ArrivalAirport }}</span>
              </div>
          </div>
        <a
          data-button="problemflight"
          class="button button--prim"
          :href="`${url}${fields.calculator_url ?? '/check-uw-vlucht'}?Action=StartProblemFlight&ProblemFlightId=${item.ProblemFlightId}`">
            {{ fields.button_text }}
        </a>                        
      </div>
    </li>
    <a class="link--read-more" v-if="fields.flight_amount_to_show !== fields.flight_amount"
      @click="calculateListHeight()">
      <div v-if="!open" class="group">
        <span>{{ fields.read_less }}</span>
        <Icon image="chevron-bottom" />
      </div>
      <div v-if="open" class="group">
        <span>{{  fields.read_more }}</span>
        <Icon image="chevron-top" />
      </div>
    </a>
  </ul>
  <p v-else-if="!busy">{{fields.no_results_message}}</p>
</template>
<script setup lang="ts">
  import Icon from '../../../assets/shared-components/Icon.vue'
  import Spinner from '../../../assets/shared-components/Spinner.vue'
  import { onMounted, ref, nextTick } from 'vue'
  import { ProblemFlightItem } from '../../../types'
  import { cultureCheck } from '../../../assets/utils/cultureCheck'
  import { dateFormatOnCulture } from '../../../assets/utils/dateFormatOnCulture'
  
  let busy = ref(true)
  let open = ref(false)

  const props = defineProps({
    fields: {
      type: Object,
      required: true
    }
  })

  const url = new URL(location.href).origin.includes('localhost')
    ? 'http://dev.euclaim.nl'
    : new URL(location.href).origin
  const endpoint = `api-v2/list/getproblemflights`
  const form = new FormData
  form.append('airlineCode', props.fields.list_type === 'airlines' ? props.fields.iata : '')
  form.append('airportCode', props.fields.list_type === 'airports' ? props.fields.iata : '')
  form.append('countryCode', props.fields.list_type === 'countries' ? props.fields.iata : '')
  form.append('culture', cultureCheck())
  form.append('limit', props.fields.flight_amount)
  const config = {
    method: 'POST',
    body: form
  }

  const list = ref<Array<ProblemFlightItem>>([])

  const domItem = ref()
  const domList = ref()

  const calculateListHeight = async () => {
    const itemHeight = domItem.value.clientHeight
    const listHeight = open.value
      ? itemHeight * (list.value.length / 2) + 'px'
      : itemHeight * (props.fields.flight_amount_to_show / 2) + 'px'
    domList.value.style.maxHeight = listHeight
    open.value = !open.value
  }

  onMounted (async () => {
    try {
      list.value = await fetch(`${url}/${endpoint}`, config).then((list) => list.json())
      // Sync shadow DOM with DOM
      await nextTick()
      calculateListHeight()
      busy.value = false
    } catch (error) {
      console.log(error)
    }
  })

</script>