// label mover v3
//
// updated to vanilla
//
// handles the class setting on a label
//
// Created by Matthias Poortvliet @ Lennoc Development 27/03/2020
const labelMover = () => {
  const inputElements = document.querySelectorAll('[data-touched]')
  const formElementWrapperClass = '.form__group'
  const classToSet = 'form__group--touched'

  for(const inputElement of inputElements) {
    inputElement.addEventListener('click', (e) => {
      setInputClass(formElementWrapperClass, classToSet, e)
    })

    inputElement.addEventListener('keydown', (e) => {
      setInputClass(formElementWrapperClass, classToSet, e)
    })

    inputElement.addEventListener('touch', (e) => {
      setInputClass(formElementWrapperClass, classToSet, e)
    })

    inputElement.addEventListener('blur', (e) => {
      blurClass(formElementWrapperClass, classToSet, e)
    })
  }
}

const setInputClass = (wrapperClass, classToSet, eventSource) => {
  const wrapper = eventSource.srcElement.closest(wrapperClass)
  if (eventSource.srcElement === document.activeElement) {
    wrapper.classList.add(classToSet)
    return
  }
  !eventSource.srcElement.value ? wrapper.classList.add(classToSet) : wrapper.classList.remove(classToSet)
}

const blurClass = (wrapperClass, classToSet, eventSource) => {
  const wrapper = eventSource.srcElement.closest(wrapperClass)
  if(!eventSource.srcElement.value) {
    wrapper.classList.remove(classToSet)
  }
}

export default labelMover()