// simple drop down v2
//
// handles the class setting for a simple drop down (no external data)
// attribute data-drop-down-select="on/off" handles the enabling of the select functionality
// order of markup and is essential for functionality: first trigger, then sibling of trigger in DOM is target, and first class on target must be the list class
// this is used for multiple different dropdowns in the website
// - navbar
// - partnerform
//
// Updated by Matthias Poortvliet @ Lennoc Development 26/03/2021
const simpleDropDown = () => {
  const triggerSelector = '[data-drop-down="trigger"]'
  const targetSelector = '[data-drop-down="target"]'
  const selectSelector = '[data-drop-down-select]'
  const inputSelector = '[data-drop-down-select="input"]'
  const dropdownSelector = '.dropdown'
  const touchedClass = 'form__group--touched'

  const select = document.querySelectorAll(selectSelector)

  if (select) {
    selectInputDropdown(select, dropdownSelector, inputSelector, touchedClass, targetSelector)
  }

  const triggers = document.querySelectorAll(triggerSelector)

  for (const trigger of triggers) {
    const target = trigger.parentElement.querySelector(targetSelector)
  
    trigger.addEventListener('click', () => {
      handleOpenDropDown(target)
    })

    window.addEventListener('click', (e) => {
      handleCloseDropdown(triggerSelector, target, targetSelector, e)
    })
  }
}

const handleOpenDropDown = (target) => {
  target.classList.add(target.classList[0] + '--visible')
}

const handleCloseDropdown = (triggerSelector, target, targetSelector, e) => {
  !e.srcElement.closest(targetSelector) && !e.srcElement.closest(triggerSelector)
    ? target.classList.remove(target.classList[0] + '--visible')
    : ''
}

const selectInputDropdown = (selectOptions, dropdownSelector, inputSelector, touchedClass, targetSelector) => {
  for (const selectTarget of selectOptions) {
    selectTarget.addEventListener('click', (e) => {
      setSelectInput(e.srcElement)
    })

    const setSelectInput = (sourceInputElement) => {
      const dropdown = sourceInputElement.closest(dropdownSelector)
      const targetInputElement = dropdown.querySelector(inputSelector)
      const valueToTransfer = sourceInputElement.innerHTML
      const dropdownContent = sourceInputElement.closest(targetSelector)
      valueToTransfer
        ? (targetInputElement.value = valueToTransfer,
          dropdown.classList.add(touchedClass),
          dropdownContent.classList.remove(dropdownContent.classList[1]))
        : ''
    }
  }
}

export default simpleDropDown()