// error handler v1
//
// handles any js error and sends it to endpoint/console
//
// Created by Matthias Poortvliet @ Lennoc Development 29/08/2021
window.onerror = (msg, url, line, col, error) => {
  const urlToLog = new URL(window.location.href)
  const domain = urlToLog.origin.includes('.nl')
    ? 'NL'
    : urlToLog.origin.includes('.de')
      ? 'DE'
      : 'UK'

  const errorToLog = {
    domain: domain,
    error: error
  }
  // make an endpoint to send these errors to
  console.log(errorToLog)
}